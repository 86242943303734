<template>
  <div class="container">
    <div class="header">
      <div class="no-gutters justify-content-center">
        <div class="p-0">
          <div id="receipt-view-container p-3 mt-4">
            <header style="text-align: center; font-size: 12px">
              <div v-show="showLogo">
                <img :src="settings.logo" style="max-width: 60%" />
              </div>
              <div v-html="settings.header"></div>
              {{ dateFormat(order.created, "DD/MM/YYYY, HH:mm:ss") }}
              <hr />
              <div class="text-center">
                <p>Client : {{ getClientName(order.accountid) }}</p>
                <p style="font-size: 16px">Reçu : {{ order.reference }}</p>
              </div>
            </header>
            <section
              style="
                font-size: 12px;
                border-top: 1px solid black;
                border-bottom: 1px solid black;
              "
            >
              <table width="100%">
                <tbody>
                  <tr v-for="line in order.lines" :key="line" style="font-size: 12px">
                    <td>
                      {{ line.name }}<br />
                      {{ line.quantity }} *
                      {{ linePriceIncltax(line) }}
                      <br />
                      <p v-if="line.discount">
                        Remise de: {{ numFormat(line.discount) }}&#37;:
                        {{ singleLineDiscountValue(line) }}
                      </p>
                    </td>
                    <td style="text-align: right" class="mb-3">
                      {{ numberFormat(getLineTotal(line), ".") }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table width="100%">
                <tbody>
                  <tr>
                    <td><strong>Total TTC</strong></td>
                    <td style="text-align: right; font-size: 20px">
                      {{ numberFormat(order.amount, ".") }} {{ currency }}
                    </td>
                  </tr>
                  <tr v-if="order.discount_amount" style="font-size: 11px">
                    <td>
                      <strong
                        >Remise ({{ numFormat(order.discount_percent) }}&#37;)</strong
                      >
                    </td>
                    <td style="text-align: right">
                      {{ numberFormat(order.discount_amount, ".") }}
                    </td>
                  </tr>
                  <tr v-if="showTax" style="font-size: 11px">
                    <td><strong>TVA</strong></td>
                    <td style="text-align: right">{{ numFormat(order.total_tax) }}</td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <p>{{ linesQuantity(order.lines) }} articles</p>
              <p v-for="(t, i) in order.transactions" :key="t.id">
                {{ t.amount }} par {{ checkoutMethodName(t.payment_method) }}
              </p>
            </section>
            <footer style="text-align: center; font-size: 12px">
              <div v-html="settings.footer"></div>
            </footer>
          </div>
          <div class="" v-if="!hideButtons">
            <div class="d-flex justify-content-center">
              <button
                class="btn btn-primary btn-block mx-2"
                @click.prevent="$emit('printReceipt')"
              >
                imprimer le reçu
              </button>
            </div>
            <div class="d-flex justify-content-center mt-2">
              <button
                v-if="order.step === 'paid'"
                :disabled="in_submition"
                class="btn btn-danger btn-block mx-2"
                @click.prevent="$emit('updateReceipt')"
              >
                Echange/retour
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@/includes/mixins";
import _ from "lodash";

export default {
  mixins: [mixins],
  props: ["showLogo", "order", "in_submition", "hideButtons"],
  data() {
    return {
      showTax: true
    }
  },
  created() {
    this.showTax = JSON.parse(localStorage.getItem('showTax'));
  },
  methods: {
    getSubTotal(lines) {
      return _.sumBy(lines, function (o) {
        return _.multiply(o.price, o.quantity);
      });
    },
    numFormat(number) {
      return this.$filters.numFormat(number);
    },
  },
};
</script>
