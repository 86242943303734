import http from "./http.service";

class ReceiptService {

    store(data) {
        return http.post('/pos/receipts', data);
    }

    receiptInvoice(id) {
        return http.post('/pos/receipt/invoice', { id });
    }

    receiptGroup(ids) {
        const data = { ids: [...ids] };
        return http.post('/pos/receipt/group', data);
    }

    getReceipt(id) {
        return http.get('/pos/receipts/' + id);
    }

    get(pageNum, data) {
        return http.post('/datatable/pos_receipt?page=' + pageNum, data);
    }

    delete(id) {
        return http.delete('/pos/receipts/' + id);
    }

    search(ref) {
        return http.post('/datatable/pos_receipt', {
            q: { reference: { contains: ref } }
        });
    }
}

export default ReceiptService;